import userRules from '../userRules'

export default [
  {
    path: '/p/r/alunos',
    icon: 'lni-users',
    name: 'Alunos',
    rule: userRules.RESPONSIBLE
  }
]
