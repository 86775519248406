import userRules from '../userRules'

export default [
  {
    path: '/admin/banners',
    icon: 'lni-gallery',
    name: 'Banners',
    rule: userRules.ADMIN
  },
  {
    path: '/admin/noticias',
    icon: 'lni-information',
    name: 'Noticias',
    rule: userRules.ADMIN,
    submenu: [
      {
        path: '/admin/noticias/novo',
        name: 'NewsForm'
      },
      {
        path: '/admin/noticias/:id/editar',
        name: 'NewsFormEdit'
      }
    ]
  },
  {
    path: '/admin/professores',
    icon: 'lni-user',
    name: 'Professores',
    rule: userRules.ADMIN,
    submenu: [
      {
        path: '/admin/professores/novo',
        name: 'TeacherForm'
      },
      {
        path: '/admin/professores/:id/editar',
        name: 'TeacherFormEdit'
      }
    ]
  },
  {
    path: '/admin/alunos',
    icon: 'lni-users',
    name: 'Alunos',
    rule: userRules.ADMIN,
    submenu: [
      {
        path: '/admin/alunos/novo',
        name: 'StudentForm'
      },
      {
        path: '/admin/alunos/:id/editar',
        name: 'StudentFormEdit'
      }
    ]
  },
  {
    path: '/admin/disciplinas',
    icon: 'lni-bookmark',
    name: 'Disciplinas',
    rule: userRules.ADMIN,
    submenu: [
      {
        path: '/admin/disciplinas/nova',
        name: 'DisciplineForm'
      },
      {
        path: '/admin/disciplinas/:id/editar',
        name: 'DisciplineFormEdit'
      }
    ]
  },
  {
    path: '/admin/series',
    icon: 'lni-bold',
    name: 'Séries',
    rule: userRules.ADMIN,
    submenu: [
      {
        path: '/admin/series/novo',
        name: 'SerieForm'
      },
      {
        path: '/admin/series/:id/editar',
        name: 'SerieFormEdit'
      }
    ]
  },
  {
    path: '/admin/turmas',
    icon: 'lni-school-bench-alt',
    name: 'Turmas',
    rule: userRules.ADMIN,
    submenu: [
      {
        path: '/admin/turmas/nova',
        name: 'ClassForm'
      },
      {
        path: '/admin/turmas/:id/editar',
        name: 'ClassFormEdit'
      },
      {
        path: '/admin/turmas/:idClass/editar/atividades/nova',
        name: 'ActivityForm'
      }
    ]
  },
  {
    path: '/admin/config',
    icon: 'lni-cog',
    name: 'Config',
    rule: userRules.ADMIN
  }
]
