<template>
  <div class="card">
    <div class="card-content">
      <div class="level content-title">
        <div class="level-left">
          <div class="level-item">
            <div class="title">
              <span class="icon icon-title">
                <i :class="icon"></i>
              </span>
              <span><slot></slot></span>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <nav class="breadcrumb is-small">
              <ul>
                <li v-for="item in breadcrumb" :key="item.text" :class="{'is-active': item.isActive}">
                  <router-link :to="item.path"> {{ item.text }}</router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    icon: String,
    breadcrumb: Array
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 2rem;
  .card-content {
    padding: 1rem;
    .title {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
    }
  }

  .icon-title {
    font-size: 1rem;
    margin-right: .5em;
    height: 1.5rem;
    width: 1.5rem;
    color: #fff;
    border-radius: .25rem;
    background-color: #23269E;
  }

  .level-right {
    display: none;
  }
}

@media (min-width: 1024px) {
  .card {
    .card-content {
      padding: 1.5rem;
      .title {
        display: block;
        font-size: 2rem;
      }

      .icon-title {
        font-size: 2rem;
        height: 3rem;
        width: 3rem;
      }

      .level-right {
        display: block;
      }
    }
  }
}
</style>
