import userRules from '../userRules'

export default [
  {
    path: '/p/t/turmas',
    icon: 'lni-school-bench-alt',
    name: 'Turmas',
    rule: userRules.TEACHER,
    submenu: [
      {
        path: '',
        name: 'TeacherActivityForm'
      },
      {
        path: '',
        name: 'TeacherActivityList'
      },
      {
        path: '',
        name: 'TeacherStudentList'
      },
      {
        path: '',
        name: 'TeacherReportCard'
      }
    ]
  },
  {
    path: '/p/t/boletim',
    icon: 'lni-library',
    name: 'Boletim'
  },
  {
    path: '/p/t/chamada',
    icon: 'lni-pencil-alt',
    name: 'Chamada'
  }
]
