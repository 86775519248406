<template>
  <aside class="sidebar is-dark" :class="{'is-close': !layout.sidebarOpen}">
    <nav class="menu active-menu--home">
      <ul class="menu-list">
        <li v-for="item in activeMenu" :key="item.name" :class="{ 'active': item.isActive || false }">
          <router-link :to="item.path">
            <i class="lni lni-lg mb-2" :class="item.icon"></i>
            <span class="nav-text">{{ item.text || item.name }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
// import userRules from '@/config/userRules'
import { mapState } from 'vuex'

export default {
  name: 'Sidebar',
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState(['layout', 'user']),
    activeMenu () {
      const filteredMenu = this.menu.filter(item => item.rule === this.user.rule)

      const menu = filteredMenu.map(item => {
        if (item.path === this.$route.path) {
          item.isActive = true
        } else {
          item.isActive = false

          if (item.submenu !== undefined) {
            item.submenu.map(subitem => {
              if (subitem.name === this.$route.name) {
                item.isActive = true
              }
            })
          }
        }

        return item
      })

      return menu
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: $adm-sidebar-width-mobile;
  min-height: 100%;
  height: auto;
  transition: width .3s;
  z-index: 9;
  &.is-dark {
    background-color: #2a2a2a;
  }

  &.is-close {
    width: $adm-sidebar-width-mobile;
    .menu-list {
      padding: 5px;
      li {
        a {
          .nav-text {
            display: none;
          }
        }
      }
    }
  }
}

.menu, .menu-list {
  height: 100%;
}

.menu-list {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.sidebar .menu-list li {
  display: flex;
  flex-direction: column;
  text-align: center;
  a {
    display: inline-block;
    padding: 0.8125rem 0.9375rem;
    i {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      color: rgba(255, 255, 255, 0.7);
    }
    .nav-text {
      display: none;
      color: rgba(255, 255, 255, 0.7);
    }

    &:hover {
      background-color: #111111;
    }
  }
  &.active, &:hover {
    background-color: #111111;
  }
  &:hover a i, &:hover a .nav-text,
  &.active a i, &.active a .nav-text {
      color: #fff;
  }
}

@media (min-width: 1024px) {
  .sidebar {
     width: $adm-sidebar-width;
     .menu-list {
       padding: 10px;
       li {
         a {
           .nav-text {
             display: block;
           }
         }
       }
     }
  }
}
</style>
