import admin from './admin'
import teacher from './teacher'
import student from './student'
import responsible from './responsible'

export default [
  ...admin,
  ...teacher,
  ...student,
  ...responsible
]
