<template>
  <header class="hero is-primary">
    <div class="hero-head">
      <nav
        class="navbar"
        :class="{'is-close': !layout.sidebarOpen}"
        role="navigation"
        aria-label="main navigation"
      >
        <div class="navbar-brand">
          <router-link class="navbar-item is--brand" to="/admin">
            <img class="navbar-brand-logo" src="@/assets/images/logo.png" alt="Coopenaza" />
          </router-link>
        </div>
        <div class="navbar-menu">
          <div class="navbar-start">
            <button class="navbar-item navbar-toogle" @click="toogleSidebar">
              <i class="lni" :class="layout.sidebarOpen ? 'lni-shift-left' : 'lni-shift-right' " />
            </button>
          </div>
          <div class="navbar-end">
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                <figure class="image is-32x32">
                  <avatar :username="user.name"></avatar>
                </figure>
                <span class="user-name">{{ user.name }}</span>
              </a>
              <div class="navbar-dropdown is-primary is-right">
                <router-link class="navbar-item" to="/p/perfil">
                  <span class="icon is-small mr-2"><i class="lni lni-user"></i></span>
                  Perfil
                </router-link>
                <a class="navbar-item" @click.prevent="_logout">
                  <span class="icon is-small mr-2"><i class="lni lni-exit"></i></span>
                  Sair
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import Avatar from 'vue-avatar'
import { mapState, mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'Navbar',
  components: {
    Avatar
  },
  computed: {
    ...mapState(['layout', 'user'])
  },
  methods: {
    ...mapActions('layout', ['toogleSidebar']),
    ...mapActions('user', ['logout']),
    _logout () {
      Swal.fire({
        title: 'Tem certeza?',
        text: 'Você tem certeza que deseja sair?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sair',
        cancelButtonText: 'Quero ficar'
      }).then(result => {
        if (result.isConfirmed) {
          this.logout()
          this.$router.push('/')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.hero, .navbar {
  height: 5rem;
  z-index: 9;
}

.navbar {
  box-shadow: 0 2px 0 0 #282a36;
  display: flex;
  align-items: center;
  &.is-close {
    .navbar-brand {
      width: $adm-sidebar-width-mobile;
    }
  }
  .navbar-menu {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    &:focus, :hover {
      margin-top: auto;
      .navbar-dropdown {
        display: block;
      }
    }
    .navbar-end {
      margin-left: auto;

      .vue-avatar--wrapper {
        width: 100% !important;
        height: 100% !important;
        background-color: #FFF !important;
        color: $primary-color !important;
      }
    }
    .navbar-start {
      display: none;
      .navbar-toogle {
        font-size: 2rem;
        margin-left: .5rem;
        border: none;
        color: $secondary-color;
        background: none;
        cursor: pointer;
        &:hover, &:focus {
          outline: none;
          opacity: .7;
        }
      }
    }
    .image {
      margin: 0 auto;
    }
    .user-name {
      display: none;
    }
    .navbar-dropdown {
      display: none;
    }
  }

  .navbar-brand {
    width: $adm-sidebar-width-mobile;
    height: 100%;
    transition: width .3s;
    .navbar-item {
      width: 100%;
      .navbar-brand-logo {
        display: block;
        margin: 0 auto;
        max-height: 3rem;
      }
    }
  }
}

@media (min-width: 1024px) {
  .navbar {
    .navbar-brand {
      width: $adm-sidebar-width;
    }

    .navbar-menu {
      width: auto;
      align-items: stretch;
      &:focus, :hover {
        margin-top: 0;
      }
      .image {
        margin-right: .5rem;
      }
      .user-name {
        display: block;
      }
      .navbar-end {
        margin: 0;
      }
      .navbar-start {
        display: flex;
      }
    }
  }
}
</style>
