<template>
  <div :class="page">
    <navbar />
    <div class="adm-wrapper" :class="{'is-close': !layout.sidebarOpen}">
      <sidebar :menu="menu" />
      <div class="adm-layout-main">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import userRules from '@/config/userRules'
import menu from '@/config/menu'
import Navbar from '@/layout/admin/Navbar'
import Sidebar from '@/layout/admin/Sidebar'

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar
  },
  props: {
    page: String
  },
  data () {
    return {
      menu: []
    }
  },
  computed: {
    ...mapState(['layout', 'user'])
  },
  created () {
    if (
      this.user.rule !== userRules.ADMIN &&
      this.user.rule !== userRules.TEACHER &&
      this.user.rule !== userRules.STUDENT &&
      this.user.rule !== userRules.RESPONSIBLE
    ) {
      this.$router.push('/login')
    }

    this.menu = menu
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables.scss";

.adm-wrapper {
  position: relative;
  width: 100%;
  min-height: $adm-wrapper-min-height;

  &.is-close {
    .adm-layout-main {
      margin-left: $adm-sidebar-width-mobile;
    }
  }

  .adm-layout-main {
    position: relative;
    top: 0;
    left: 0;
    min-height: $adm-wrapper-min-height;
    margin-left: $adm-sidebar-width-mobile;
    padding: 20px 15px;
    transition: margin .3s;
  }
}

@media (min-width: 1024px) {
  .adm-wrapper {
    .adm-layout-main {
      margin-left: $adm-sidebar-width;
      padding: 20px;
    }
  }
}
</style>
